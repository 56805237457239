import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ArchiveGrid({ hideTitle = false, setDeletedPosts }) {
  const [posts, setPosts] = useState([]); // ✅ Default to empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // 3x2 grid

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/blogs/archived`);
        if (!response.ok) throw new Error("Failed to fetch posts");

        const data = await response.json();
        setPosts(Array.isArray(data.posts) ? data.posts.filter((post) => post.status === "archived") : []); // ✅ Ensure posts is always an array
      } catch (err) {
        console.error(err.message);
        setError("Failed to load posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // ✅ Ensure posts is an array before calling slice()
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Array.isArray(posts) ? posts.slice(indexOfFirstPost, indexOfLastPost) : [];

  const nextPage = () => {
    if (indexOfLastPost < posts.length) setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const getThumbnail = (media) => {
    const firstImage = media?.find((item) => item.type === "image");
    if (firstImage) return firstImage.url;

    const firstVideo = media?.find((item) => item.type === "youtube");
    if (firstVideo) {
      const videoIdMatch = firstVideo.url.match(
        /(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/
      );
      return videoIdMatch ? `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg` : "/logo192.png";
    }

    return "/logo192.png"; // Fallback image
  };

  const handleUndoArchive = async (postId) => {
    if (!window.confirm("⚠️ Are you sure you want to restore this post to active?")) return;

    try {
      const response = await fetch(`${API_BASE_URL}/api/blogs/${postId}/unarchive`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to undo archive");

      // ✅ Update UI: Remove restored post from archive list
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));

      toast.success("✅ Post restored to active.");
    } catch (error) {
      console.error("❌ Error undoing archive:", error.message);
      toast.error("❌ Failed to undo archive.");
    }
  };

  const handleDeletePost = async (postId) => {
    if (!window.confirm("⚠️ Move this post to Trash? It will be permanently deleted when Trash is cleared.")) {
      return;
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/blogs/${postId}/mark-delete`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });
  
      if (!response.ok) throw new Error("Failed to move post to Trash");
  
      // Remove from Archive UI
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  
      fetchDeletedPosts();
      
      // Fetch Updated Deleted Posts
      fetch(`${API_BASE_URL}/api/blogs/deleted`)
        .then((res) => res.json())
        .then((data) => setDeletedPosts(data.posts));
  
      toast.warn("🚮 Post moved to Trash.");
    } catch (error) {
      console.error("❌ Error moving post to Trash:", error.message);
      toast.error("❌ Failed to delete post.");
    }
    window.location.reload();
  };
  
  if (loading) return <div className="text-center text-white">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!Array.isArray(posts) || posts.length === 0) return <div className="text-center text-gray-400">No archived posts found.</div>;

  return (
    <div className="w-full mb-0 flex flex-col items-center">
      {!hideTitle && <h1 className="text-4xl font-bold mb-6 text-center">Archived Posts</h1>}

      {/* ✅ Fixed Grid: Always 3x2 Layout */}
      <div className="grid grid-cols-3 gap-4 w-full max-w-xl mx-auto justify-center">
        {currentPosts.map((post) => {
          const thumbnail = getThumbnail(post.media);

          return (
            <motion.div
              key={post.id}
              className="relative bg-gray-800 rounded-lg shadow-lg cursor-pointer w-full max-w-[20rem] p-4"
              whileHover={{
                scale: 1.05,
                rotate: Math.random() * (0.5 - -0.5) + -0.5,
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
              onHoverEnd={() => ({
                rotate: 0,
                transition: { duration: 0.3, ease: "easeInOut" },
              })}
            >
              {/* ❌ Delete Button */}
              <button
                onClick={() => handleDeletePost(post.id)}
                className="absolute top-1 right-1 font-bold bg-red-500 text-black hover:text-white text-lg px-2 rounded-sm hover:bg-red-700"
              >
                x
              </button>

              <Link to={`/blog/${post.id}`}>
                <div className="w-full aspect-[16/9] overflow-hidden rounded-md">
                  <img
                    src={thumbnail}
                    alt={post.title}
                    className="w-full h-full object-cover"
                    onError={(e) => (e.target.src = "/logo192.png")}
                  />
                </div>
                <div className="mt-2 text-center">
                  <h2 className="text-lg font-semibold text-white">{post.title}</h2>
                  <p className="text-xs text-gray-400">{new Date(post.created_at).toLocaleDateString()}</p>
                </div>
              </Link>

              <div className="text-center mt-2">
                {/* 🔄 Undo Archive Button (Under Date with Spacing) */}
                <button
                  onClick={() => handleUndoArchive(post.id)}
                  className="px-3 bg-green-600 hover:bg-green-700 text-white text-sm font-semibold rounded-md"
                >
                  🔄 Restore
                </button>
              </div>
            </motion.div>
          );
        })}
      </div>

      {/* Pagination Buttons - Always Show "Previous" After First Page */}
      <div className="mt-10 flex gap-4 justify-center">
        {currentPage > 1 && (
          <button
            onClick={prevPage}
            className="bg-gray-700 px-4 py-2 text-white rounded-md"
          >
            Previous
          </button>
        )}
        {indexOfLastPost < posts.length && (
          <button
            onClick={nextPage}
            className="bg-gray-700 px-4 py-2 text-white rounded-md"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default ArchiveGrid;
