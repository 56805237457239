import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";

function HomeGrid({ hideTitle = false }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/blogs`);
        if (!response.ok) {
          throw new Error("Failed to fetch posts");
        }
        const data = await response.json();

        // ✅ Filter only active posts
        const activePosts = data.posts.filter((post) => post.status === "active");

        setPosts(activePosts.slice(0, 25)); // Limit to 25 posts
      } catch (err) {
        console.error(err.message);
        setError("Failed to load posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  const getThumbnail = (media) => {
    const safeMedia = media || [];

    const firstImage = safeMedia.find((item) => item.type === "image");
    if (firstImage) return firstImage.url;

    const firstVideo = safeMedia.find((item) => item.type === "youtube");
    if (firstVideo) {
      const videoIdMatch = firstVideo.url.match(/(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/);
      if (videoIdMatch) return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`;
    }

    return "/logo192.png"; // Fallback image
  };

  if (loading) return <div className="text-center text-white">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (posts.length === 0) return <div className="text-center text-gray-400">No blog posts found.</div>;

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-6xl w-full px-4">
        {!hideTitle && <h1 className="text-4xl font-bold mb-6 text-center">All Blog Posts</h1>}
        <div className="flex flex-wrap justify-center gap-6">
          {posts.map((post) => {
            const thumbnail = getThumbnail(post.media);

            return (
              <div
                key={post.id}
                className="relative bg-gray-800 rounded-lg shadow-lg cursor-pointer transition duration-300 transform hover:scale-105 w-64"
              >
                <Link to={`/blog/${post.id}`}>
                  <div className="w-full aspect-[16/9] overflow-hidden rounded-md">
                    <img
                      src={thumbnail}
                      alt={post.title}
                      className="w-full h-full object-cover"
                      onError={(e) => (e.target.src = "/default-thumbnail.png")}
                    />
                  </div>
                  <div className="mt-2 text-center p-2">
                    <h2 className="text-lg font-semibold text-white">{post.title}</h2>
                    <p className="text-xs text-gray-400">
                      {new Date(post.created_at).toLocaleDateString()}
                    </p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HomeGrid;
