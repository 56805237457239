// src/components/ManageUsers.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../../../components/Navbar.jsx';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 8;
  const loggedInUser = localStorage.getItem('username');
  const navigate = useNavigate();

  const fetchCalled = useRef(false);

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      toast.error('Authentication token missing. Please log in.');
      return;
    }
  
    try {
      console.log("Fetching users...");
      const response = await fetch(`${BASE_URL}/api/users`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log("Response status:", response.status);
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Fetch error:", errorText);
        toast.error(`Error fetching users: ${errorText}`);
        return;
      }
  
      const data = await response.json();
      console.log("Fetched users:", data); // Log full response
  
      if (!data || !data.users || !Array.isArray(data.users)) {
        console.error("Error: 'users' key not found in API response", data);
        toast.error("Error loading users: Invalid response format.");
        return;
      }
  
      setUsers([...data.users].sort((a, b) => a.username.localeCompare(b.username)));
    } catch (error) {
      console.error("Fetch failed:", error);
      toast.error('An error occurred while fetching users.');
    }
  };
  

  const handleAddUser = async () => {
    if (!newUsername.trim()) {
      toast.error('Username cannot be empty.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication token missing. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ 
          username: newUsername.trim(), 
          isAdmin, 
          password: 'password123' 
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        toast.error(`Error adding user: ${errorText}`);
        return;
      }

      toast.success('User added successfully!');
      fetchUsers();
      setNewUsername('');
      setIsAdmin(false);
    } catch (error) {
      toast.error('An error occurred while adding the user.');
    }
  };

  const handleRemoveUser = async (userId, username) => {
    if (username === loggedInUser) {
      toast.error('You cannot remove yourself.');
      return;
    }

    const confirmRemoval = window.confirm(`Are you sure you want to remove user "${username}"?`);
    if (!confirmRemoval) return;

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication token missing. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        toast.error(`Error removing user: ${errorText}`);
        return;
      }

      toast.success(`User "${username}" removed successfully.`);
      fetchUsers();
    } catch (error) {
      toast.error('An error occurred while removing the user.');
    }
  };

  const handleToggleAdmin = async (userId, username, currentStatus) => {
    if (username === loggedInUser) {
      toast.error('You cannot change your own admin status.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication token missing. Please log in.');
      return;
    }

    const action = currentStatus ? 'demote' : 'promote';
    const confirmAction = window.confirm(`Are you sure you want to ${action} "${username}"?`);
    if (!confirmAction) return;

    try {
      const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ isAdmin: !currentStatus }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        toast.error(`Error updating admin status: ${errorText}`);
        return;
      }

      toast.success(`User "${username}" admin status updated.`);
      fetchUsers();
    } catch (error) {
      toast.error('An error occurred while updating admin status.');
    }
  };

  const totalPages = Math.ceil(users.length / usersPerPage);
  const currentUsers = users.slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (!fetchCalled.current) {
      fetchUsers();
      fetchCalled.current = true;
    }
  }, []);
  
  

  return (
    <div className="flex items-center justify-center min-h-screen py-10 bg-black text-white">
      <Navbar />
      <div className="w-full max-w-3xl p-6 bg-gray-800 rounded-lg shadow-lg mt-10">
        <h2 className="text-3xl font-bold mb-6 text-center">Manage Users</h2>
        
        <div className="mb-8">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission behavior
              handleAddUser();
            }}
            className="flex items-center space-x-4 mb-4"
          >
            <input
              type="text"
              placeholder="Enter username"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              className="flex-grow p-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={isAdmin}
                onChange={() => setIsAdmin(!isAdmin)}
                className="mr-2 form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="text-white">Admin</span>
            </label>
          </form>
          <button
            onClick={handleAddUser}
            className="w-full px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg transition duration-200"
          >
            Add User
          </button>
        </div>


        <div>
          <h3 className="text-2xl font-bold mb-4 text-center">Current Users</h3>
          {users.length === 0 ? (
            <p className="text-center">No users found.</p>
          ) : (
            <ul className="space-y-4">
              {currentUsers.map((user) => (
                <li key={user.id} className="flex justify-between items-center p-4 bg-gray-700 rounded-lg">
                  <div className="flex flex-col">
                    <span className="text-lg font-medium">{user.username}</span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={user.is_admin}
                        onChange={() => handleToggleAdmin(user.id, user.username, user.is_admin)}
                        className="h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-sm">Admin</span>
                    </label>
                    <button
                      onClick={() => handleRemoveUser(user.id, user.username)}
                      className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg transition duration-200"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {users.length > usersPerPage && (
            <div className="flex justify-between mt-6">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-200"
              >
                Previous
              </button>
              <span className="text-lg">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-200"
              >
                Next
              </button>
            </div>
          )}
        </div>

        <button
          onClick={() => navigate(-1)}
          className="w-full mt-8 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-200"
        >
          Back to Admin Panel
        </button>
      </div>
    </div>
    
  );
}

export default ManageUsers;
