import React, { useEffect, useState } from "react";
import ProjectTile from "./ProjectTile.jsx";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ITEMS_PER_PAGE = 8;

function HomeGrid() {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/content`);
        const data = await response.json();
        setProjects(data.filter((item) => item.type === "project"));
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const scrollContainer = document.querySelector(".home-scroll-container");
    if (scrollContainer) scrollContainer.scrollTop = 0;
  }, []);

  const totalPages = Math.ceil(projects.length / ITEMS_PER_PAGE);
  const showPagination = !isMobile && totalPages > 1;

  const currentProjects = isMobile
    ? projects
    : projects.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <div className="home-scroll-container flex flex-col items-center justify-center w-full max-w-screen-lg h-[calc(100vh-8rem)] overflow-y-auto mx-auto">
      {/* Centered Grid Container */}
      <div className="w-full flex justify-center">
        <div
          className={`w-full max-w-3xl min-h-[40vh] p-4 ${
            currentProjects.length === 1
              ? "flex justify-center items-center" // Center single item
              : " grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 place-items-center"
          }`}
        >
          {currentProjects.length > 0 ? (
            currentProjects.map((project, index) => (
              <ProjectTile
                key={project.id}
                title={project.title}
                url={project.url}
                thumbnail={project.thumbnail_url}
                description={project.description}
                media={project.media || []}
                index={index}
              />
            ))
          ) : (
            <p className="text-gray-400 text-lg text-center w-full">No projects available</p>
          )}
        </div>
      </div>

      {/* Pagination Controls - Only show on desktop */}
      {showPagination && (
        <div className="mt-6 mb-3 flex gap-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-6 py-2 text-white rounded-md hover:bg-white-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            ← Previous
          </button>

          <span className="text-white text-lg">Page {currentPage} of {totalPages}</span>

          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-6 py-2 text-white rounded-md hover:bg-white-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next →
          </button>
        </div>
      )}
    </div>
  );
}

export default HomeGrid;
