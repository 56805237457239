import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function ExperiencePreview({ content, onSave }) {
  const experience = content.find((item) => item.type === "experience");
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    if (experience) {
      setText(experience.description || "");
    }
  }, [experience]);

  if (!experience) return null;

  return (
    <section className="w-full flex flex-col items-center gap-10 px-6 py-16 text-white">
      <h2 className="text-3xl sm:text-5xl font-bold">Experience</h2>

      <div
        className="w-full max-w-4xl bg-gray-800 p-6 rounded-lg shadow-md text-center cursor-pointer"
        onClick={() => setIsEditing(true)}
      >
        {isEditing ? (
          <div className="flex flex-col gap-4 items-center">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={6}
              className="w-full p-3 bg-gray-900 text-white rounded resize-none"
              placeholder="Add your experience details..."
            />
            <button
              className="bg-green-600 px-6 py-2 rounded hover:bg-green-700"
              onClick={() => {
                onSave(experience.id, "description", text);
                toast.success("Experience section updated!");
                setIsEditing(false);
              }}
            >
              Save Text
            </button>
          </div>
        ) : (
          <p className="text-lg sm:text-xl leading-relaxed">
            {text || "Click here to add your experience description."}
          </p>
        )}
      </div>
    </section>
  );
}
