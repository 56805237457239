import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../../../components/Navbar";
import HeroPreview from "./HeroPreview";
import AboutPreview from "./AboutPreview";
import SkillsPreview from "./SkillsPreview";
import ExperiencePreview from "./ExperiencePreview";
import ProjectPreview from "./ProjectPreview";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ManageHomepage() {
  const [content, setContent] = useState([]);

  const SECTION_TYPES = ["hero", "about", "skills", "experience"];

  const fetchContent = async () => {
    try {
      const res = await fetch(`${BASE_URL}/api/content`);
      let data = await res.json();
  
      const existingTypes = data.map(item => item.type);
      const missingTypes = SECTION_TYPES.filter(type => !existingTypes.includes(type));
  
      // If any section is missing, create it with blank content
      if (missingTypes.length > 0) {
        await Promise.all(
          missingTypes.map(type =>
            fetch(`${BASE_URL}/api/content`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                type,
                title: "",
                description: "",
                url: "",
                thumbnail_url: ""
              }),
            })
          )
        );
  
        // Re-fetch after insertions
        const updatedRes = await fetch(`${BASE_URL}/api/content`);
        data = await updatedRes.json();
      }
  
      setContent(data);
    } catch (error) {
      console.error("Error fetching or initializing content:", error);
    }
  };


  const updateContentField = async (id, field, value) => {
    try {
      const res = await fetch(`${BASE_URL}/api/content/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ field, value }),
      });
  
      const data = await res.json(); // 👈 inspect backend response
      console.log("Patch response:", data);
  
      if (res.ok) {
        console.log("Updated!");
        fetchContent();
      } else {
        toast.error(data.message || "Update failed");
      }
    } catch (err) {
      toast.error("Error updating content");
      console.error(err);
    }
  };
  

  useEffect(() => {
    document.title = 'Manage Home';
    fetchContent();
  }, []);

  console.log("Final content loaded:", content);

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      <div className="pt-24 pb-12 flex flex-col items-center gap-24">
        <HeroPreview content={content} onSave={updateContentField} />
        <AboutPreview content={content} onSave={updateContentField} />
        <SkillsPreview content={content} onSave={updateContentField} />
        <ProjectPreview content={content} onSave={updateContentField} />
        <ExperiencePreview content={content} onSave={updateContentField} />
      </div>
    </div>
  );
}

export default ManageHomepage;
