export const isYouTubeUrl = (url) => /youtu\.?be|youtube\.com/.test(url);

export const isInstagramUrl = (url) => /instagram\.com/.test(url);

export const getYouTubeVideoId = (url) => {
  const match = url.match(/(?:v=|\/)([0-9A-Za-z_-]{11})/);
  return match ? match[1] : null;
};

export const getYouTubeTitle = async (url) => {
  const videoId = getYouTubeVideoId(url);
  if (!videoId) return "YouTube Video";
  try {
    const res = await fetch(
      `https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`
    );
    const data = await res.json();
    return data.title || "YouTube Video";
  } catch {
    return "YouTube Video";
  }
};
