import { useState } from 'react';
import { motion } from 'framer-motion';

export default function ContactSection() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent(`Message from ${name}`);
    const body = encodeURIComponent(message);
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=drckgomz@gmail.com&su=${subject}&body=${body}`, '_blank');
  };

  return (
    <motion.section
      id="contact"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7 }}
      viewport={{ once: true }}
      className="w-full h-screen flex items-center justify-center px-6"
    >
      <div className="max-w-3xl w-full text-center">
        <h2 className="text-3xl sm:text-5xl font-bold mb-4">Let's Connect</h2>
        <p className="text-lg sm:text-xl mb-6">
          Whether you're interested in collaborating, hiring, or just vibing with tech and design — reach out!
        </p>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
          <input
            type="text"
            placeholder="Your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full max-w-md p-3 bg-gray-800 text-white rounded"
          />
          <textarea
            placeholder="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            required
            className="w-full max-w-md p-3 bg-gray-800 text-white rounded"
          />
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded text-white font-semibold"
          >
            Send via Gmail
          </button>
        </form>
      </div>
    </motion.section>
  );
}
