import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function SkillsSection() {
  const [skillsContent, setSkillsContent] = useState(null);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const res = await fetch(`${BASE_URL}/api/content`);
        const data = await res.json();
        const skillsEntry = data.find((item) => item.type === 'skills');
        setSkillsContent(skillsEntry);
      } catch (error) {
        console.error('Error fetching Skills section:', error);
      }
    };

    fetchSkills();
  }, []);

  return (
    <motion.section
      id="skills"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7 }}
      viewport={{ once: true }}
      className="w-full min-h-screen flex flex-col items-center gap-10 justify-center px-6 py-16 text-white"
    >
      <h2 className="text-3xl sm:text-5xl font-bold text-center">Skills</h2>

      {skillsContent?.description ? (
        <p className="max-w-4xl text-center text-lg sm:text-xl leading-relaxed text-white/90">
          {skillsContent.description}
        </p>
      ) : (
        <p className="text-gray-400 text-center">No skills content yet.</p>
      )}
    </motion.section>
  );
}
