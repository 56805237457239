import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";

function ProjectList({ projects, setProjects, handleUpdateProject }) {
  const [editId, setEditId] = useState(null);
  const [editedProject, setEditedProject] = useState({});
  const [newProject, setNewProject] = useState({ title: "", description: "", url: "" });

  const handleEditClick = (project) => {
    setEditId(project.id);
    setEditedProject({ ...project });
  };

  const handleCancelEdit = () => {
    setEditId(null);
    setEditedProject({});
  };

  const handleSaveEdit = async () => {
    const fields = ["title", "description", "url"];
    try {
      for (const field of fields) {
        if (editedProject[field] !== projects.find(p => p.id === editId)[field]) {
          await handleUpdateProject(editId, field, editedProject[field]);
        }
      }
      setEditId(null);
      setEditedProject({});
    } catch (error) {
      toast.error("Error saving project edits.");
      console.error(error);
    }
  };

  const handleAddProject = async () => {
    const { title, description, url } = newProject;
    if (!title.trim() || !description.trim() || !url.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/content`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          type: "project",
          title,
          description,
          url,
          thumbnail_url: "",
        }),
      });

      if (res.ok) {
        const { project: created } = await res.json();
        setProjects((prev) => [...prev, created]);
        setNewProject({ title: "", description: "", url: "" });
        toast.success("Project added.");
      } else {
        toast.error("Error adding project.");
      }
    } catch (error) {
      toast.error("Error creating project.");
      console.error(error);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedProjects = [...projects];
    const [movedProject] = reorderedProjects.splice(result.source.index, 1);
    reorderedProjects.splice(result.destination.index, 0, movedProject);

    setProjects(reorderedProjects);
    saveOrderToDB(reorderedProjects);
  };

  const saveOrderToDB = async (newOrder) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/content/reorder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ order: newOrder.map((p) => p.id) }),
      });
    } catch (error) {
      console.error("Error saving project order:", error);
    }
  };

  const handleDeleteProject = async (id) => {
    if (!window.confirm("Are you sure you want to delete this project?")) return;

    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/content/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (!res.ok) throw new Error("Failed to delete");

      toast.success("Project deleted.");
      setProjects((prev) => prev.filter((p) => p.id !== id));
    } catch (error) {
      toast.error("Error deleting project.");
      console.error(error);
    }
  };

  return (
    <>
      {/* Add Project Form */}
      <div className="bg-gray-900 p-4 rounded-lg space-y-3 mb-6 w-full max-w-4xl">
        <input
          type="text"
          placeholder="Project Title"
          className="w-full p-2 rounded bg-gray-800 text-white"
          value={newProject.title}
          onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
        />
        <textarea
          placeholder="Project Description"
          className="w-full p-2 rounded bg-gray-800 text-white resize-none"
          value={newProject.description}
          onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
        />
        <input
          type="text"
          placeholder="Project URL"
          className="w-full p-2 rounded bg-gray-800 text-white"
          value={newProject.url}
          onChange={(e) => setNewProject({ ...newProject, url: e.target.value })}
        />
        <div className="flex justify-center">
          <button
            onClick={handleAddProject}
            className="bg-green-600 hover:bg-green-700 px-6 py-2 rounded text-white"
          >
            Add Project
          </button>
        </div>
      </div>

      {/* Project List */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="projectList">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
              {projects.map((project, index) => (
                <Draggable key={project.id} draggableId={String(project.id)} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="p-4 bg-gray-700 rounded-lg cursor-grab"
                    >
                      {editId === project.id ? (
                        <div className="space-y-2">
                          <input
                            className="w-full p-2 rounded bg-gray-800 text-white"
                            value={editedProject.title}
                            onChange={(e) => setEditedProject({ ...editedProject, title: e.target.value })}
                          />
                          <textarea
                            className="w-full p-2 rounded bg-gray-800 text-white resize-none"
                            value={editedProject.description}
                            onChange={(e) => setEditedProject({ ...editedProject, description: e.target.value })}
                          />
                          <input
                            className="w-full p-2 rounded bg-gray-800 text-white"
                            value={editedProject.url}
                            onChange={(e) => setEditedProject({ ...editedProject, url: e.target.value })}
                          />
                          <div className="flex justify-end gap-2">
                            <button onClick={handleSaveEdit} className="bg-green-600 px-3 py-1 rounded">Save</button>
                            <button onClick={handleCancelEdit} className="bg-gray-600 px-3 py-1 rounded">Cancel</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h3 className="text-xl font-semibold">{project.title}</h3>
                          <p className="text-sm text-gray-300">{project.description}</p>
                          <div className="mt-2 flex justify-between items-center">
                            <a href={project.url} target="_blank" rel="noopener noreferrer" className="text-blue-400">
                              View Project
                            </a>
                            <div className="flex gap-2">
                              <button
                                onClick={() => handleEditClick(project)}
                                className="text-yellow-400 hover:text-yellow-600"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteProject(project.id)}
                                className="text-red-500 hover:text-red-700"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default ProjectList;
