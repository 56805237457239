import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import MediaCarousel from "../Blog/comp/MediaCarousel";

export default function ProjectModal({ title, url, description, media = [], onClose }) {
  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50 bg-black bg-opacity-80 overflow-y-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="min-h-screen flex items-center justify-center px-4 py-24">
          <motion.div
            className="bg-gray-900 rounded-xl shadow-xl w-full max-w-3xl relative text-white p-6 sm:p-8"
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 30, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-5 text-white text-2xl hover:text-red-400"
            >
              ×
            </button>

            {/* Carousel */}
            {media.length > 0 && (
              <div className="mb-6">
                <MediaCarousel media={media} />
              </div>
            )}

            {/* Title */}
            <h2 className="text-3xl font-bold mb-3">{title}</h2>

            {/* Visit Project Button */}
            {url && (
              <div className="mb-6 flex justify-center">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md text-white text-sm font-medium"
                >
                  Visit Project ↗
                </a>
              </div>
            )}

            {/* Description */}
            {description ? (
              <p className="text-gray-300 whitespace-pre-line">{description}</p>
            ) : (
              <p className="text-gray-500 italic">No description provided.</p>
            )}
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
