import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";

function DeletedGrid({ hideTitle = false, setArchivedPosts }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // 3x2 grid

  useEffect(() => {
    fetchDeletedPosts();
  }, [currentPage]);

  const fetchDeletedPosts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/blogs/deleted`);
      if (!response.ok) throw new Error("Failed to fetch deleted posts");

      const data = await response.json();
      setPosts(Array.isArray(data.posts) ? data.posts : []); // ✅ Ensure array
    } catch (err) {
      console.error(err.message);
      setError("Failed to load deleted posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Array.isArray(posts) ? posts.slice(indexOfFirstPost, indexOfLastPost) : [];

  const nextPage = () => {
    if (indexOfLastPost < posts.length) setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const getThumbnail = (media) => {
    const firstImage = media?.find((item) => item.type === "image");
    if (firstImage) return firstImage.url;

    const firstVideo = media?.find((item) => item.type === "youtube");
    if (firstVideo) {
      const videoIdMatch = firstVideo.url.match(
        /(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/
      );
      return videoIdMatch ? `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg` : "/logo192.png";
    }

    return "/logo192.png"; // Fallback image
  };

  // ✅ Undo Delete Functionality
  const handleUndoDelete = async (postId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/blogs/${postId}/restore`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to restore post");

      // ✅ Remove from Deleted Grid
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));

      // ✅ Fetch updated Archive Grid
      const archiveResponse = await fetch(`${BASE_URL}/api/blogs/archived`);
      if (!archiveResponse.ok) throw new Error("Failed to fetch archive");

      const archiveData = await archiveResponse.json();
      setArchivedPosts(archiveData.posts);
      toast.success("✅ Post restored to archive.");
      window.location.reload();
    } catch (error) {
      console.error("❌ Error restoring post:", error.message);
      toast.error("❌ Failed to restore post.");
    }
  };

  // ✅ Clear Trash Functionality
  const handleClearTrash = async () => {
    if (!window.confirm("⚠️ Are you sure? This will permanently delete all posts in the Trash.")) return;

    try {
      const response = await fetch(`${BASE_URL}/api/blogs/trash/clear-trash`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to clear deleted posts");

      setPosts([]); // ✅ Clear UI
      toast.success("🗑️ Trash cleared successfully.");
    } catch (error) {
      console.error("❌ Error clearing trash:", error.message);
      toast.error("❌ Failed to clear trash.");
    }
  };

  if (loading) return <div className="text-center text-white">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="w-full flex flex-col items-center mt-0">
      {!hideTitle && <h1 className="text-4xl font-bold mb-6 text-center">Trash</h1>}

      {posts.length === 0 && <div className="text-center text-gray-400">No posts marked for deletion.</div>}

      {/* ✅ Fixed Grid: Always 3x2 Layout */}
      <div className="grid grid-cols-3 py-2 gap-4 w-full max-w-xl mx-auto justify-center">
        {currentPosts.map((post) => (
          <motion.div
            key={post.id}
            className="relative bg-gray-800 rounded-lg shadow-lg cursor-pointer w-full max-w-[20rem] p-2"
            whileHover={{
              scale: 1.05,
              rotate: Math.random() * (0.5 - -0.5) + -0.5,
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
          >
            {/* ⟲ Undo Delete Button */}
            <button
              onClick={() => handleUndoDelete(post.id)}
              className="absolute top-1 right-1 bg-yellow-500 font-bold text-black hover:text-white text-lg px-2 rounded-sm hover:bg-yellow-700"
            >
              ⟲
            </button>

            <Link to={`/blog/${post.id}`}>
              <div className="w-full aspect-[16/9] overflow-hidden rounded-md">
                <img
                  src={getThumbnail(post.media)}
                  alt={post.title}
                  className="w-full h-full object-cover"
                  onError={(e) => (e.target.src = "/logo192.png")}
                />
              </div>
              <div className="mt-2 text-center p-2">
                <h2 className="text-lg font-semibold text-white">{post.title}</h2>
                <p className="text-xs text-gray-400">{new Date(post.created_at).toLocaleDateString()}</p>
              </div>
            </Link>
          </motion.div>
          
        ))}
      </div>

      {/* ✅ Pagination Buttons */}
      {posts.length > postsPerPage && (
        <div className="mt-10 flex gap-4 justify-center">
          {currentPage > 1 && (
            <button
              onClick={prevPage}
              className="bg-gray-700 px-4 py-2 text-white rounded-md"
            >
              Previous
            </button>
          )}
          {indexOfLastPost < posts.length && (
            <button
              onClick={nextPage}
              className="bg-gray-700 px-4 py-2 text-white rounded-md"
            >
              Next
            </button>
          )}
        </div>
      )}
      {/* ✅ Clear Trash Button */}
      {posts.length > 0 && (
        <button
          onClick={handleClearTrash}
          className="mb-4 px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md"
        >
          🗑️ Clear
        </button>
      )}
    </div>
  );
}

export default DeletedGrid;
