import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function ExperienceSection() {
  const [experience, setExperience] = useState(null);

  useEffect(() => {
    const fetchExperience = async () => {
      try {
        const res = await fetch(`${BASE_URL}/api/content`);
        const data = await res.json();
        const exp = data.find((item) => item.type === 'experience');
        setExperience(exp);
      } catch (error) {
        console.error('Error fetching Experience section:', error);
      }
    };

    fetchExperience();
  }, []);

  return (
    <motion.section
      id="experience"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7 }}
      viewport={{ once: true }}
      className="w-full min-h-screen flex flex-col items-center justify-center gap-10 px-6 py-16 text-white"
    >
      <h2 className="text-3xl sm:text-5xl font-bold text-center">Experience</h2>

      <div className="w-full max-w-4xl">
        {experience?.description ? (
          <p className="text-lg sm:text-xl text-center leading-relaxed text-white/90">
            {experience.description}
          </p>
        ) : (
          <p className="text-center text-lg text-gray-400">No experience content yet.</p>
        )}
      </div>
    </motion.section>
  );
}
