import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Get current URL path to determine if we are on the admin page
  const isAdminPage = window.location.pathname === "/admin";

  // Check if the user is an admin
  useEffect(() => {
    try {
      const adminStatus = localStorage.getItem('isAdmin') === 'true';
      console.log('Admin status in Navbar:', adminStatus);
      setIsAdmin(adminStatus);
    } catch (error) {
      console.error('Error parsing isAdmin value:', error);
      setIsAdmin(false);
    }
  }, []);

  // Track scroll position to shrink navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle logout and redirect to terminal
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('isAdmin');
    navigate('/terminal');
  };

  // Navigate back to the blog homepage
  const handleLogoClick = () => {
    navigate('/BlogHome');
  };

  // Navigate to the Admin Panel
  const navigateToAdmin = () => {
    navigate('/admin');
  };

  const navigateToSettings = () => {
    navigate('/settings');
  }

  return (
    <div
      className={`fixed top-0 w-full bg-black text-white z-50 transition-all duration-300
        ${isScrolled ? 'py-2 shadow-md' : 'py-4 shadow-lg'}`}
    >
      <div className="container mx-auto flex justify-between items-center px-4 sm:px-6">
        {/* Logo (remains large) */}
        <div
          className="font-bold md:text-4xl sm:text-2xl text-2xl cursor-pointer transition-all duration-300"
          onClick={handleLogoClick}
        >
          drckgomz
        </div>

        {/* Buttons: Hide "Admin Panel" if on the Admin Page */}
        <div className="flex items-center space-x-2 sm:space-x-4">
          {isAdmin && !isAdminPage && (
            <button onClick={navigateToAdmin} className="px-3 py-1 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg transition-all duration-300">
              Admin Panel
            </button>
          )}

          {/* Settings Button*/}
          <button onClick={navigateToSettings} className="px-3 py-1 bg-orange-600 hover:bg-orange-700 text-white font-semibold rounded-lg transition-all duration-300" >
            Settings
          </ button>



          <button onClick={handleLogout} className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg transition-all duration-300">
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
