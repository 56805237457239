import React from "react";
import { 
    isYouTubeUrl,
    isInstagramUrl,
    getYouTubeTitle,
    getYouTubeVideoId
 } from "./utils/mediaHelpers";

const MediaList = ({ mediaList, onInsert, onRemove }) => {
  return (
    <div className="space-y-4 overflow-y-auto p-1 bg-gray-800 rounded-lg max-h-[calc(100vh-150px)]">
      <h2 className="text-xl font-semibold ">Media List</h2>
      {mediaList.map((media) => (
        <div
          key={media.id}
          className="flex items-center p-3 bg-gray-900 rounded-lg"
        >
          {media.type === "image" && (
            <img
              src={media.url}
              alt={media.title}
              className="h-16 w-16 object-cover rounded-lg"
            />
          )}
          {media.type === "instagram" && (
            <div className="h-16 w-16 flex items-center justify-center bg-pink-600 rounded-lg">
              <span className="text-white text-sm">IG</span>
            </div>
          )}
          {media.type === "youtube" && (
            <img
              src={`https://img.youtube.com/vi/${getYouTubeVideoId(media.url)}/hqdefault.jpg`}
              alt="YouTube Thumbnail"
              className="h-16 w-16 object-cover rounded-lg"
            />
          )}
          <div className="flex-1 ml-3">
            <p className="text-white text-sm">{media.title}</p>
            <button
              onClick={() => onInsert(media.id)}
              className="text-blue-400 hover:underline text-sm"
            >
              Insert
            </button>
          </div>
          <button
            onClick={() => onRemove(media.id)}
            className="ml-3 px-2 py-1 bg-red-600 hover:bg-red-700 text-white font-bold rounded"
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};

export default MediaList;
