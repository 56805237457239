import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../../components/Navbar';
import Footer from '../../comp/BlogFooter';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

function EditResume() {
  const [resume, setResume] = useState('');
  const navigate = useNavigate();

  const fetchResume = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/content`);
      const data = await response.json();
      const resumeData = data.find((item) => item.type === 'resume');
      setResume(resumeData ? resumeData.description : '');
    } catch (error) {
      console.error('Error fetching resume:', error);
    }
  };

  const handleSaveResume = async () => {
    await fetch(`${BASE_URL}/api/content`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ type: 'resume', description: resume }),
    });

    alert('Resume updated!');
  };

  useEffect(() => {
    document.title = 'Edit Resume';
    fetchResume();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {/* Navbar at the Top */}
      <Navbar />

      {/* Centered Resume Form */}
      <div className="flex-grow mt-20 flex items-center justify-center">
        <div className="w-full max-w-3xl bg-gray-800 rounded-lg shadow-lg p-6">
          <h1 className="text-3xl font-bold text-center mb-4">Edit Resume</h1>

          <textarea
            className="w-full h-60 p-4 bg-gray-700 text-white rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={resume}
            onChange={(e) => setResume(e.target.value)}
          />

          <div className="flex justify-center mt-4">
            <button
              onClick={handleSaveResume}
              className="w-28  font-bold px-6 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg transition duration-200"
            >
              Save
            </button>
          </div>

          <div className="flex justify-center mt-2">
            <button
              onClick={() => navigate(-1)}
              className="w-28 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md"
            >
              ← Back
            </button>
          </div>
        </div>
      </div>

      {/* Footer Stuck to Bottom */}
      <Footer />
    </div>
  );
}

export default EditResume;
