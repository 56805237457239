import React, { use, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../../components/Navbar";
import MediaCarousel from "../comp/MediaCarousel";
import BlogContent from "./BlogContent";
import BlogFooter from "../comp/BlogFooter";

function BlogPostView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [undoTimeout, setUndoTimeout] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    setIsAdmin(localStorage.getItem("isAdmin") === "true");
  }, []);

  useEffect(() => {
    if (!id) return console.error("❌ Error: Blog post ID is undefined!");

    const fetchPost = async () => {
      try {
        console.log(`🔍 Fetching blog post with ID: ${id}`);
        const response = await fetch(`${API_BASE_URL}/api/blogs/${id}`);
        if (!response.ok) throw new Error("Failed to fetch the blog post");
        const data = await response.json();

        console.log("✅ Blog Post Data:", data.blogPost);
        setPost(data.blogPost || {});
      } catch (error) {
        console.error("❌ Fetch Error:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const handleArchive = async () => {
    if (!window.confirm("⚠️ Are you sure you want to archive this post?")) return;

    try {
      const response = await fetch(`${API_BASE_URL}/api/blogs/${id}/archive`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to archive post");

      setPost((prevPost) => ({ ...prevPost, status: "archived" }));
      toast.info("📁 Post moved to archive.");
    } catch (error) {
      console.error("❌ Error archiving post:", error.message);
      toast.error("❌ Failed to archive post.");
    }
  };

  const handleUndoArchive = async () => {
    clearTimeout(undoTimeout);
    setUndoTimeout(null);

    try {
      const response = await fetch(`${API_BASE_URL}/api/blogs/${id}/unarchive`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to undo archive");

      setPost((prevPost) => ({ ...prevPost, status: "active" }));
      toast.success("✅ Post has been restored!");
    } catch (error) {
      console.error("❌ Error undoing archive:", error.message);
      toast.error("❌ Failed to undo archive.");
    }
  };

  const handleDeletePost = async () => {
    if (!window.confirm("⚠️ Move this post to Trash? It will be permanently deleted when Trash is cleared.")) {
      return;
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/blogs/${id}/mark-delete`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });
  
      if (!response.ok) throw new Error("Failed to move post to Trash");
  
      // ✅ Update the post status instantly
      setPost((prevPost) => ({ ...prevPost, status: "deleted" }));
  
      // ✅ Fetch and update Deleted Posts immediately
      const deletedResponse = await fetch(`${API_BASE_URL}/api/blogs/deleted`);
      if (!deletedResponse.ok) throw new Error("Failed to fetch deleted posts");
  
      const deletedData = await deletedResponse.json();
      if (typeof setDeletedPosts === "function") {
        setDeletedPosts(deletedData.posts);  // ✅ Instantly updates the Deleted Grid
      }
  
      toast.warn("🚮 Post moved to Trash.");
    } catch (error) {
      console.error("❌ Error moving post to Trash:", error.message);
      toast.error("❌ Failed to delete post.");
    }
  };
  

  const handleUndoDelete = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/blogs/${id}/restore`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      });
  
      if (!response.ok) throw new Error("Failed to restore post");
  
      // ✅ Update the post status instantly
      setPost((prevPost) => ({ ...prevPost, status: "archived" }));
  
      // ✅ Fetch Updated Archive Grid
      const archiveResponse = await fetch(`${API_BASE_URL}/api/blogs/archived`);
      if (!archiveResponse.ok) throw new Error("Failed to fetch archive");
  
      const archiveData = await archiveResponse.json();
      if (typeof setArchivedPosts === "function") {
        setArchivedPosts(archiveData.posts);  // ✅ Instantly updates Archive Grid
      }
  
      toast.success("✅ Post restored to archive.");
    } catch (error) {
      console.error("❌ Error restoring post:", error.message);
      toast.error("❌ Failed to restore post.");
    }
  };
  

  useEffect (() => {
    if (post && post.title) {
      document.title = post.title;
    }
  },[post]);
    

  if (loading) return <div className="text-center text-white">Loading...</div>;
  if (!post) return <div className="text-center text-gray-400">Blog post not found.</div>;

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />

      <div className="container mx-auto p-6 relative z-0">
        <MediaCarousel media={post.media} />

        <h1 className="text-4xl font-bold mb-8 mt-20">{post.title}</h1>
        
        <div className="text-sm text-gray-400 mb-6 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <span>
              Created: {new Date(post.created_at).toLocaleDateString()}
              {post.updated_at && !isNaN(new Date(post.updated_at)) && (
                <> | Updated: {new Date(post.updated_at).toLocaleDateString()}</>
              )}
            </span>

            {isAdmin && (
              <button
                onClick={() => navigate(`/edit/${post.id}`)}
                className="px-3 py-1 bg-yellow-600 hover:bg-yellow-700 text-white text-sm font-semibold rounded-md"
              >
                Edit Post
              </button>
            )}
          </div>

          {isAdmin && (
            <div className="flex gap-2">
              {post.status === "active" && (
                <button
                  onClick={handleArchive}
                  className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white text-sm font-semibold rounded-md"
                >
                  Archive
                </button>
              )}

              {post.status === "archived" && (
                <>
                  <button
                    onClick={handleUndoArchive}
                    className="px-3 py-1 bg-green-600 hover:bg-green-700 text-white text-sm font-semibold rounded-md"
                  >
                    Undo Archive
                  </button>
                  <button
                    onClick={handleDeletePost}
                    className="px-3 py-1 bg-red-700 hover:bg-red-800 text-white text-sm font-semibold rounded-md"
                  >
                    Mark for Deletion
                  </button>
                </>
              )}

              {post.status === "deleted" && (
                <button
                  onClick={handleUndoDelete}
                  className="px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold rounded-md"
                >
                  Undo Delete
                </button>
              )}
            </div>
          )}

        </div>

        <BlogContent content={post.content} media={post.media} />

        <div className="flex justify-center pt-28 mt-6">
        <button
          onClick={() => navigate(-1)}
          className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md"
        >
          ← Back
        </button>

        </div>
      </div>

      <BlogFooter />
    </div>
  );
}

export default BlogPostView;
