import React from "react";
import ProjectList from "./ProjectList";

export default function ProjectPreview({ content, onSave }) {
  const projects = content.filter((item) => item.type === "project");

  return (
    <section className="w-full flex flex-col items-center px-6 text-white">
      <h2 className="text-3xl sm:text-5xl font-bold mb-6">Project Section</h2>
      <div className="w-full max-w-4xl">
        <ProjectList
          projects={projects}
          setProjects={() => {}}
          handleDeleteProject={(id) => onSave(id, "delete", true)} // Optional customization
        />
      </div>
    </section>
  );
}