import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function AboutSection() {
  const [aboutContent, setAboutContent] = useState({ image_url: '', text: '' });

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const res = await fetch(`${BASE_URL}/api/content`);
        const data = await res.json();
        const about = data.find((item) => item.type === 'about');
        if (about) {
          setAboutContent({ image_url: about.thumbnail_url, text: about.description });
        }
      } catch (error) {
        console.error('Error fetching About section:', error);
      }
    };

    fetchAbout();
  }, []);

  return (
    <motion.section
      id="about"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7 }}
      viewport={{ once: true }}
      className="w-full min-h-screen flex flex-col items-center justify-center px-6 text-white"
    >
      <h2 className="text-3xl sm:text-5xl font-bold mb-8 text-center">About Me</h2>

      <div className="flex flex-col sm:flex-row items-center gap-8 max-w-4xl">
        {aboutContent.image_url ? (
          <img
            src={aboutContent.image_url}
            alt="About Me"
            className="w-48 h-48 rounded-full object-cover shadow-md"
          />
        ) : (
          <div className="w-48 h-48 rounded-full bg-white bg-opacity-10 border border-white flex items-center justify-center text-white text-sm">
            About Image
          </div>
        )}

        <p className="text-lg sm:text-xl text-center sm:text-left max-w-2xl">
          {aboutContent.text || 'Loading...'}
        </p>
      </div>
    </motion.section>
  );
}
