import React, { useState, useEffect } from 'react';

const HiddenVideo = ({ videoRef, setVideoFalse, videoSrc }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [ffSpeedIndex, setFfSpeedIndex] = useState(0);
  const [rwSpeedIndex, setRwSpeedIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [rewindInterval, setRewindInterval] = useState(null);
  const [showReplay, setShowReplay] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  


  const forwardSpeeds = [1, 2, 4, 6];
  const rewindSpeeds = [1, 4, 6];
  const [disableControls, setDisableControls] = useState(false);

  const debounceControls = () => {
    setDisableControls(true);
    setTimeout(() => setDisableControls(false), 250);
  };

  const handlePlayPause = async () => {
    if (disableControls || !videoRef.current) return;
    debounceControls();

    const video = videoRef.current;

    if (rewindInterval) {
      clearInterval(rewindInterval);
      setRewindInterval(null);
    }

    try {
      if (isPlaying) {
        video.pause();
        video.playbackRate = 1;
        setIsPlaying(false);
      } else {
        const speed = forwardSpeeds[ffSpeedIndex] || 1;
        video.playbackRate = speed;
        await video.play();
        setIsPlaying(true);
      }

      setFfSpeedIndex(0);
      setRwSpeedIndex(0);
    } catch (err) {
      console.error("Error toggling play/pause:", err);
    }
  };

  const handleToggleMute = () => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };
  

  const handleFastForward = () => {
    if (disableControls || !videoRef.current || !isPlaying) return;
    debounceControls();

    if (rwSpeedIndex > 0) {
      const newRwIndex = rwSpeedIndex - 1;
      setRwSpeedIndex(newRwIndex);
      if (newRwIndex === 0 && rewindInterval) {
        clearInterval(rewindInterval);
        setRewindInterval(null);
      }
      return;
    }

    if (rewindInterval) {
      clearInterval(rewindInterval);
      setRewindInterval(null);
    }

    const nextIndex = (ffSpeedIndex + 1) % forwardSpeeds.length;
    setFfSpeedIndex(nextIndex);
    videoRef.current.playbackRate = forwardSpeeds[nextIndex];
  };

  const handleRewind = () => {
    if (disableControls || !videoRef.current || !isPlaying) return;
    debounceControls();

    if (ffSpeedIndex > 0) {
      const newFfIndex = ffSpeedIndex - 1;
      setFfSpeedIndex(newFfIndex);
      videoRef.current.playbackRate = forwardSpeeds[newFfIndex];
      return;
    }

    if (rewindInterval) clearInterval(rewindInterval);

    const nextIndex = (rwSpeedIndex + 1) % rewindSpeeds.length;
    setRwSpeedIndex(nextIndex);

    const skipAmount = rewindSpeeds[nextIndex];
    const interval = setInterval(() => {
      if (videoRef.current.currentTime > skipAmount) {
        videoRef.current.currentTime -= skipAmount;
      } else {
        videoRef.current.currentTime = 0;
        clearInterval(interval);
      }
    }, 500);

    setRewindInterval(interval);
  };

  const resetVideo = () => {
    setVideoFalse();
    setIsPlaying(false);
    setFfSpeedIndex(0);
    setRwSpeedIndex(0);
    if (rewindInterval) clearInterval(rewindInterval);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.playbackRate = 1;
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch((err) => console.error('Autoplay failed:', err));
      }
    }

    const handleKeyDown = (e) => {
      if (e.code === 'Space') {
        e.preventDefault();
        handlePlayPause();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    let timeout;
    if (showControls) {
      timeout = setTimeout(() => setShowControls(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [showControls]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleEnded = () => {
      setIsPlaying(false);
      setShowReplay(true);
      setFfSpeedIndex(0);
      setRwSpeedIndex(0);
      video.playbackRate = 1;
      video.pause();
      video.currentTime = video.duration;
    };

    const handleTimeUpdate = () => {
      if (!video.duration) return;
      setProgress((video.currentTime / video.duration) * 100);
    
      if (rewindInterval && video.currentTime <= 0.01) {
        setIsPlaying(false);
        clearInterval(rewindInterval);
        setRewindInterval(null);
        setRwSpeedIndex(0);
        video.pause();
        video.currentTime = 0;
      }
    };
    

    video.addEventListener('ended', handleEnded);
    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [rewindInterval]);

  return (
    <div
      id="video-container"
      className="fixed inset-0 z-30 flex justify-center items-center bg-black bg-opacity-75 animate-fadeIn"
    >
      <div
        className="relative w-[90vw] h-[80vh] max-w-5xl group overflow-hidden rounded-lg"
        onMouseMove={() => setShowControls(true)}
        onClick={handlePlayPause}
      >
        <video
          ref={videoRef}
          id="hidden-video"
          preload="auto"
          className="w-full h-full rounded-lg"
          disablePictureInPicture
          controlsList="nodownload nofullscreen noremoteplayback"
          muted={isMuted}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>

        {/* Close Button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            resetVideo();
          }}
          className="absolute top-2 right-2 text-white bg-red-500 hover:bg-red-700 font-bold py-1 px-3 rounded z-50"
        >
          X
        </button>


        {/* Speed Indicator */}
        {(ffSpeedIndex > 0 || rwSpeedIndex > 0) && (
          <div className="absolute top-4 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-black bg-opacity-80 border border-white text-white text-2xl font-mono tracking-widest shadow-[0_0_6px_white] z-40">
            {ffSpeedIndex > 0
              ? `>> x${forwardSpeeds[ffSpeedIndex]}`
              : `<< x${rewindSpeeds[rwSpeedIndex]}`}
          </div>
        )}

        {/* Icon-Only Retro Controls */}
          <div
            className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-6 transition-opacity duration-500 ${
              showControls ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRewind();
              }}
              disabled={disableControls}
              className="text-white text-3xl bg-transparent rounded-full p-2 hover:brightness-125"
            >
              ⏴︎⏴︎
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handlePlayPause();
              }}
              disabled={disableControls}
              className="text-white text-3xl bg-transparent rounded-full p-2 hover:brightness-125"
            >
              {isPlaying ? '⏸︎ ' : '▶'}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleFastForward();
              }}
              disabled={disableControls}
              className="text-white text-3xl bg-transparent rounded-full p-2 hover:brightness-125"
            >
              ⏵︎⏵︎
            </button>
          </div>

          {/* Mute Button - Retro Icon Style */}
          <div
            className={`absolute bottom-4 left-4 transition-opacity duration-500 ${
              showControls ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleToggleMute();
              }}
              className="text-white text-2xl bg-transparent rounded-full p-2 hover:brightness-125"
              title={isMuted ? 'Unmute' : 'Mute'}
            >
              {isMuted ? '🔇' : '🔊'}
            </button>
          </div>


          {showReplay && (
          <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
            <button
              onClick={async (e) => {
                e.stopPropagation();
                if (videoRef.current) {
                  try {
                    setIsPlaying(true);
                    setShowReplay(false);
                    setFfSpeedIndex(0);
                    setRwSpeedIndex(0);
                    if (rewindInterval) {
                      clearInterval(rewindInterval);
                      setRewindInterval(null);
                    }
                    videoRef.current.currentTime = 0;
                    videoRef.current.playbackRate = 1;
                    await videoRef.current.play();
                  } catch (err) {
                    console.error("Replay failed:", err);
                  }
                }
              }}
              className="pointer-events-auto text-white text-4xl bg-black bg-opacity-60 p-4 rounded-full hover:scale-110 transition-transform z-40"
            >
              ⟳
            </button>
          </div>
        )}



        {/* Progress Bar (bound to video width) */}
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-600 rounded-b-lg overflow-hidden right-0 left-0">
          <div
            className="h-full bg-prompt-color transition-all duration-100"
            style={{ width: `${progress}%` }}
          ></div>
        </div>

        
       
      </div>
      

    </div>
  );
};

export default HiddenVideo;
