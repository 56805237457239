import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../../components/Navbar";
import BlogFooter from "../../comp/BlogFooter";
import ArchiveGrid from "./ArchiveGrid";
import DeletedGrid from "./DeletedGrid";


const BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ArchivePage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [archivedPosts, setArchivedPosts] = useState([]);
  const [deletedPosts, setDeletedPosts] = useState([]);

  useEffect(() => {
    document.title = 'Archive';
    const token = localStorage.getItem("token");
    const loggedInUser = localStorage.getItem("username");

    if (!token) {
      navigate("/terminal");
    } else {
      setUsername(loggedInUser);
    }

    fetchArchivedPosts();
    fetchDeletedPosts();
  }, [navigate]);

  const fetchArchivedPosts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/blogs/archived`);
      if (!response.ok) throw new Error("Failed to fetch archived posts");

      const data = await response.json();
      setArchivedPosts(data.posts);
    } catch (error) {
      console.error("Error fetching archived posts:", error);
    }
  };

  const fetchDeletedPosts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/blogs/deleted`);
      if (!response.ok) throw new Error("Failed to fetch deleted posts");

      const data = await response.json();
      setDeletedPosts(data.posts);
    } catch (error) {
      console.error("Error fetching deleted posts:", error);
    }
  };

  return (
      <div className="flex flex-col min-h-screen bg-black text-white">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <main className="flex-grow px-6 w-full max-w-7xl mx-auto mt-28 flex flex-col items-center">
        
        {/* ✅ Wrap Both Grids in a More Compact Layout */}
        <div className="w-full flex flex-col items-center">
          {/* ✅ Archive Grid - Smaller Cards & Tighter Grid */}
          <div className="w-full">
            <ArchiveGrid posts={archivedPosts} setDeletedPosts={setDeletedPosts} />
          </div>

          {/* ✅ Deleted Grid - Ensure Better Fit */}
          <div className="w-full pt-4">
            <DeletedGrid setDeletedPosts={setDeletedPosts} setArchivedPosts={setArchivedPosts} posts={deletedPosts} />
          </div>
        </div>
        <button
            onClick={() => navigate(-1)}
            className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg"
          >
            ← Back
      </button>

      </main>

      {/* Footer */}
      <BlogFooter />
    </div>
  );
}  

export default ArchivePage;
