import React from 'react';



const videoSources = [
  "https://s3.us-east-1.amazonaws.com/www.drckgomz.com/dockBackground.webm",
  "https://s3.us-east-1.amazonaws.com/www.drckgomz.com/patio2Background.webm"
];


const VideoBackground = () => {
  const randomIndex = Math.floor(Math.random() * videoSources.length);
  const selectedVideo = videoSources[randomIndex];

  return (
    <div id="video-background" className="absolute inset-0 z-0">
      <video
        id="background-video"
        playsInline
        autoPlay
        muted
        loop
        preload="auto"
        className="object-cover h-full w-full"
        disablePictureInPicture
        controlsList="nodownload nofullscreen noremoteplayback"
      >
        <source src={selectedVideo} type="video/webm" />
      </video>
    </div>
  );
};

export default VideoBackground;
