import ProjectModal from "./ProjectModal"; // your new component
import React, {useState} from "react";

const COLORS = ["bg-red-500", "bg-blue-500", "bg-green-500", "bg-yellow-500", "bg-purple-500", "bg-pink-500", "bg-teal-500"];

export default function ProjectTile({ title, url, thumbnail, description, media, index }) {
  const bgColor = COLORS[index % COLORS.length];
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className={`border border-white-color rounded-xl w-44 h-44 flex flex-col items-center justify-center text-center`}
      >
        {thumbnail ? (
          <img
            src={thumbnail}
            alt={title}
            className="w-full h-48 object-cover rounded-t-lg"
          />
        ) : (
          <div className={`w-full h-48 rounded-t-xl flex items-center justify-center text-white font-bold ${bgColor}`}>
            No Image
          </div>
        )}

        <div className="border border-white-color rounded-xl w-full bg-black bg-opacity-20 p-3 text-center">
          <h3 className="text-white text-lg font-semibold">{title}</h3>
        </div>
      </button>

      {showModal && (
        <ProjectModal
          title={title}
          url={url}
          description={description}
          media={media}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}
