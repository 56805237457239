import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BlogFooter() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  // Check if the user is an admin
  useEffect(() => {
    try {
      const adminStatus = localStorage.getItem('isAdmin') === 'true';
      console.log('Admin status in BlogFooter:', adminStatus);
      setIsAdmin(adminStatus);
    } catch (error) {
      console.error('Error parsing isAdmin value:', error);
      setIsAdmin(false);
    }
  }, []);

  // Navigate to the Admin Panel
  const navigateToAdmin = () => {
    navigate('/admin');
  };

  return (
    <div className="w-full p-4 bg-transparent text-center z-20 sm:block">
      <div className="flex justify-center space-x-6">
        {/* YouTube Icon */}
        <a
          href="https://www.youtube.com/@drckgomz"
          className="text-white hover:opacity-60"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://s3.us-east-1.amazonaws.com/www.drckgomz.com/youtube.png" alt="YouTube" className="w-8 h-8 sm:w-10 sm:h-10 object-contain hover:opacity-60" />
        </a>

        {/* X (Twitter) Icon */}
        <a
          href="https://x.com/DrckGomz"
          className="text-white hover:opacity-60"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://s3.us-east-1.amazonaws.com/www.drckgomz.com/x.png" alt="X.com / Twitter" className="w-8 h-8 sm:w-10 sm:h-10 object-contain hover:opacity-60" />
        </a>

        {/* Instagram Icon */}
        <a
          href="https://www.instagram.com/drckgomz/"
          className="text-white hover:opacity-60"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://s3.us-east-1.amazonaws.com/www.drckgomz.com/instagram.png" alt="Instagram" className="w-8 h-8 sm:w-10 sm:h-10 object-contain hover:opacity-60" />
        </a>
      </div>
    </div>
  );
}

export default BlogFooter;
