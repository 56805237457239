import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function AboutPreview({ content, onSave }) {
  const about = content.find((item) => item.type === "about");
  const [isEditingText, setIsEditingText] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [text, setText] = useState(about?.description || "");
  const [imageUrl, setImageUrl] = useState(about?.thumbnail_url || "");

  useEffect(() => {
    if (about) {
      setText(about.description || "");
      setImageUrl(about.thumbnail_url || "");
    }
  }, [about]);

  if (!about) return null;

  return (
    <section className="w-full flex flex-col items-center gap-10 px-6 py-16 text-white">
      <h2 className="text-3xl sm:text-5xl font-bold">About Me</h2>

      {/* Image Section */}
      <div className="flex flex-col items-center gap-3">
        <div
          className="w-40 h-40 sm:w-48 sm:h-48 rounded-full overflow-hidden border-2 border-white shadow-md bg-gray-700 flex items-center justify-center cursor-pointer"
          onClick={() => setIsEditingImage(true)}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="About Thumbnail"
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "";
                setImageUrl("");
              }}
            />
          ) : (
            <span className="text-sm text-white opacity-60">About Thumbnail</span>
          )}
        </div>

        {isEditingImage && (
          <div className="flex flex-col items-center gap-2">
            <input
              type="text"
              placeholder="Image URL"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="p-2 bg-gray-800 text-white rounded w-64"
            />
            <button
              className="bg-green-600 px-4 py-1 rounded hover:bg-green-700"
              onClick={() => {
                onSave(about.id, "thumbnail_url", imageUrl);
                about.thumbnail_url = imageUrl;
                setIsEditingImage(false);
              }}
            >
              Save Image
            </button>
          </div>
        )}
      </div>

      {/* Text Section */}
      <div className="w-full max-w-4xl bg-gray-800 p-6 rounded-lg shadow-md cursor-pointer" onClick={() => setIsEditingText(true)}>
        {isEditingText ? (
          <div className="flex flex-col gap-4 items-center">
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={6}
            className="w-full p-3 bg-gray-900 text-white rounded resize-none"
          />
          <button
            className="bg-green-600 px-6 py-2 rounded hover:bg-green-700"
            onClick={() => {
              console.log("Saving text with:", { id: about.id, field: "description", value: text });
              onSave(about.id, "description", text);
              about.description = text;
              setIsEditingText(false);
              toast.success("About section updated!");
            }}
          >
            Save Text
          </button>
        </div>
        
        ) : (
          <p className="text-lg sm:text-xl leading-relaxed">
            {text || "Click here to add a description."}
          </p>
        )}
      </div>
    </section>
  );
}
