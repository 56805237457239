import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Terminal.css';
import Header from './Header.jsx';
import VideoBackground from './VideoBackground.jsx';
import TerminalInput from './TerminalInput.jsx';
import HiddenVideo from './HiddenVideo.jsx';
import AudioPlayer from './AudioPlayer.jsx';
import HomeFooter from '../Home/HomeFooter.jsx';

function Terminal() {
  const [terminalOutput, setTerminalOutput] = useState('');
  const [input, setInput] = useState('');
  const [isHiddenVideoPlaying, setIsHiddenVideoPlaying] = useState(false);
  const [hiddenVideoSrc, setHiddenVideoSrc] = useState('');
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [songSrc, setSongSrc] = useState('');
  const [user, setUser] = useState('');
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const videoRef = useRef(null);
  const inputRef = useRef(null);
  const terminalOutputRef = useRef(null);
  const navigate = useNavigate(); // Navigation hook
  const [loginAttempts, setLoginAttempts] = useState(0);

  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(0);



  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const S3_BASE_URL = process.env.REACT_APP_S3_AUDIO_URL;
  let doYouRemember = `${S3_BASE_URL}/do-u-remember.mp3`;
  let someoneToBe = `${S3_BASE_URL}/Someone-to-be.mp3`;
  let brokenVideo = `${S3_BASE_URL}/broken.mp4`;


  useEffect(() => {
    document.title = 'Terminal';

    // Preload the media on page load
    setSongSrc(someoneToBe);
    setHiddenVideoSrc(brokenVideo);
    setSongSrc(doYouRemember);
  }, [brokenVideo, doYouRemember, someoneToBe]);

  const handleClear = () => {
    console.log('Clearing terminal');
    setIsHiddenVideoPlaying(false);
    setIsAudioPlaying(false);
    setTerminalOutput('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const setVideoFalse = () => {
    console.log('Setting hidden video to false');
    setIsHiddenVideoPlaying(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const typeWriterEffect = async (text) => {
    console.log(`Typing text: ${text}`);
    for (const char of text) {
      setTerminalOutput((prev) => prev + char);
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
  };

  useEffect(() => {
    if (isHiddenVideoPlaying && videoRef.current) {
      videoRef.current.volume = 0.3;
      videoRef.current.play();
      console.log('Playing hidden video');
    }
  }, [isHiddenVideoPlaying]);

  useEffect(() => {
    if (terminalOutputRef.current) {
      terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
      console.log('Scrolled to the bottom of the terminal output');
    }
  }, [terminalOutput]);

  const checkAccess = async (username) => {
    console.log(`Checking access for username: ${username}`);
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/check-access`, { // Fix here
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ username }),
      });
    
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log(`Response from check-access: ${JSON.stringify(data)}`);
      
      if (data.hasAccess) {
        setUser(data.username);
        setLoginAttempts(0);
        await typeWriterEffect(`\nUser found: ${data.username}. Please enter your password.`);
        setIsCheckingAccess(false);
      } else {
        await typeWriterEffect('\nUser not found');
      }
    } catch (error) {
      console.error('Error checking access:', error);
      await typeWriterEffect('\nUser not found, please enter valid user or command\n-h for help');
    }
  };
  
  const loginUser = async (username, password) => {
    console.log(`Logging in with username: ${username} and password: ${password}`);
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
  
      const data = await response.json();
      console.log(`Response from login: ${JSON.stringify(data)}`);
  
      if (data.token) {
        console.log(`Login successful, isAdmin: ${data.isAdmin}`);
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', username);
      
        // Store isAdmin only if it is defined, default to 'false' otherwise
        localStorage.setItem('isAdmin', data.isAdmin ? 'true' : 'false');
      
        // Reset login attempts on successful login
        setLoginAttempts(0);
        navigate('/BlogHome');
      }
      else {
        console.log('Login failed');
        await typeWriterEffect('\nLogin failed. Invalid credentials.');
  
        // Increment login attempts
        setLoginAttempts((prevAttempts) => prevAttempts + 1);
  
        // Check if the user has reached the maximum attempts
        if (loginAttempts + 1 >= 3) {
          await typeWriterEffect('\nToo many failed attempts. Please enter your username again.');
          setUser(''); // Reset the username
          setIsCheckingAccess(true); // Start the process over
          setLoginAttempts(0); // Reset the attempts counter
        } else {
          await typeWriterEffect(`\nYou have ${3 - (loginAttempts + 1)} attempts left.`);
        }
      }
    } catch (error) {
      console.error('Error logging in user:', error);
      await typeWriterEffect('\nAn error occurred during login. Please try again.');
    }
  };

  const setInputFromHistory = (cmd) => setInput(cmd);
  

  // Define commands and their actions
  const commands = {
    'someone to be': async () => {
      console.log('Processing command: someone to be');
      await typeWriterEffect('\nPlaying "someone to be"');

      setSongSrc(someoneToBe);
      setIsAudioPlaying(true);
    },
    'chisholm': async () => {
      console.log('Processing command: chisholm');
      await typeWriterEffect('\nPlaying hidden video (broken)');
      
      setIsAudioPlaying(false);
      setHiddenVideoSrc(brokenVideo);
      setIsHiddenVideoPlaying(true);
    },
    'remember': async () => {
      console.log('Processing command: remember');
      await typeWriterEffect('\nPlaying "do you remember"');
      
      setSongSrc(doYouRemember);
      setIsAudioPlaying(true);
    },
    
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`Input submitted: ${input}`);
    if (input.trim().length > 0) {
      setHistory((prev) => [...prev, input]);
      setHistoryIndex((prev) => prev + 1);
      setTerminalOutput((prev) => prev + `\n> ${input}`);
      const command = input.trim().toLowerCase();

      setInput('');
      // Universal commands available at any time
      if (command === 'h' || command === 'help' || command === '-h') {
        await typeWriterEffect('\nUtil Commands:\n- Clear or Stop: clear the terminal\n- Commands:\n\t- someone to be\n\t- chisholm\n\t- remember');
        
        return;
      }

      if (command === 'clear' || command === 'stop') {
        handleClear();
        
        return;
      }

      if (command === 'forgot password') {
        await typeWriterEffect('\nPlease enter your username to reset the password:');
        
        return;
      }

      // Check if command exists in the commands object
      if (commands[command]) {
        await commands[command](); // Call the corresponding command function
      } else if (isCheckingAccess) {
        await checkAccess(input.trim());
      } else if (user && !isCheckingAccess) {
        await loginUser(user, input.trim());
      } else {
        await typeWriterEffect(`\nCommand not found: ${command}`);
      }

      
    }
  };

  return (
    <div className="relative h-screen w-screen bg-black text-white overflow-hidden">
      <Header />
      <VideoBackground />
      <div
        id="terminal"
        className="relative z-10 bg-black bg-opacity-50 w-full max-w-4xl mx-auto mt-48 h-[30vh] border border-prompt-color rounded-lg overflow-hidden"
      >
        <div
          id="terminal-output"
          ref={terminalOutputRef}
          className="text-xl whitespace-pre-wrap text-prompt-color p-6 h-full overflow-y-auto justify-center font-bold"
        >
          {terminalOutput}
        </div>
        <TerminalInput
          input={input}
          setInput={setInput}
          handleSubmit={handleSubmit}
          inputRef={inputRef}
          history={history}
          historyIndex={historyIndex}
          setHistoryIndex={setHistoryIndex}
          setInputFromHistory={setInputFromHistory}
        />
      </div>
      <AudioPlayer isAudioPlaying={isAudioPlaying} songSrc={songSrc} />
      {isHiddenVideoPlaying && <HiddenVideo videoRef={videoRef} setVideoFalse={setVideoFalse} videoSrc={hiddenVideoSrc} />}
      <HomeFooter />
    </div>
  );
}

export default Terminal;

