import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import BlogFooter from "../comp/BlogFooter";

function UserSettings() {
  const [user, setUser] = useState({ username: "", email: "" });
  const [editing, setEditing] = useState({ username: false, email: false });
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [passwords, setPasswords] = useState({ current: "", new: "", confirm: "" });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
      document.title = 'Settings';
    },[]);

  const fetchUserInfo = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/me`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const data = await res.json();
      if (res.ok) {
        setUser({ id: data.id, username: data.username, email: data.email });
      }
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  
  const handleUpdate = async (e) => {
    e.preventDefault();
  
    if (!validateEmail(user.email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/${user.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(user),
      });
      if (res.ok) {
        const data = await res.json();
        if (data.token) {
          localStorage.setItem("token", data.token); // ✅ Refresh JWT
        }
        alert("Profile updated! Check your email to verify changes.");
        setEditing({ username: false, email: false });
        await fetchUserInfo();
      } else {
        alert("Error updating profile.");
      }
    } catch (error) {
      console.error("Update failed:", error);
    }
  };
  

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwords.new !== passwords.confirm) {
      return alert("New passwords do not match.");
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/change-password`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(passwords),
      });
      if (res.ok) {
        alert("Password changed!");
        setPasswords({ current: "", new: "", confirm: "" });
        setShowPasswordFields(false);
      } else {
        alert("Password change failed.");
      }
    } catch (error) {
      console.error("Password change error:", error);
    }
  };

  return (
      <div className="min-h-screen bg-black text-white flex flex-col">
        <Navbar />
        <div className="flex-grow flex items-center justify-center py-16 px-4">
          <div className="p-8 w-full max-w-md">
            <h2 className="text-3xl font-bold mb-6 text-center">User Settings</h2>
    
            <form onSubmit={handleUpdate} className="space-y-4 mb-10">
              <div>
                <label className="block mb-1">Username</label>
                <input
                  type="text"
                  readOnly={!editing.username}
                  value={user.username}
                  onClick={() => setEditing({ ...editing, username: true })}
                  onChange={(e) => setUser({ ...user, username: e.target.value })}
                  className={`w-full p-2 rounded bg-gray-800 text-white ${!editing.username && "cursor-pointer"}`}
                />
              </div>
              <div>
                <label className="block mb-1">Email</label>
                <input
                  type="email"
                  readOnly={!editing.email}
                  value={user.email}
                  onClick={() => setEditing({ ...editing, email: true })}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  className={`w-full p-2 rounded bg-gray-800 text-white ${!editing.email && "cursor-pointer"}`}
                />
              </div>
              {(editing.username || editing.email) && (
                <div className="flex gap-4">
                  <button
                    type="button"
                    className="bg-gray-600 px-4 py-2 rounded hover:bg-gray-700 w-full"
                    onClick={() => {
                      fetchUserInfo();
                      setEditing({ username: false, email: false });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700 w-full"
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </form>
    
            {!showPasswordFields ? (
              <div className="text-gray-400 flex justify-between items-center">
                <span>Password: ***</span>
                <button
                  className="text-sm text-blue-400 hover:underline"
                  onClick={() => setShowPasswordFields(true)}
                >
                  Change Password
                </button>
              </div>
            ) : (
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <h3 className="text-xl font-semibold">Change Password</h3>

                {/* Current Password */}
                <div className="relative">
                  <input
                    type={showPassword.current ? "text" : "password"}
                    placeholder="Current Password"
                    value={passwords.current}
                    onChange={(e) => setPasswords({ ...passwords, current: e.target.value })}
                    className="w-full p-2 rounded bg-gray-800 text-white"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword({ ...showPassword, current: !showPassword.current })}
                    className="absolute right-2 top-2 text-sm text-blue-400 hover:underline"
                  >
                    {showPassword.current ? "Hide" : "Show"}
                  </button>
                </div>

                {/* New Password */}
                <div className="relative">
                  <input
                    type={showPassword.new ? "text" : "password"}
                    placeholder="New Password"
                    value={passwords.new}
                    onChange={(e) => setPasswords({ ...passwords, new: e.target.value })}
                    className="w-full p-2 rounded bg-gray-800 text-white"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword({ ...showPassword, new: !showPassword.new })}
                    className="absolute right-2 top-2 text-sm text-blue-400 hover:underline"
                  >
                    {showPassword.new ? "Hide" : "Show"}
                  </button>
                </div>

                {/* Confirm New Password */}
                <div className="relative">
                  <input
                    type={showPassword.confirm ? "text" : "password"}
                    placeholder="Confirm New Password"
                    value={passwords.confirm}
                    onChange={(e) => setPasswords({ ...passwords, confirm: e.target.value })}
                    className="w-full p-2 rounded bg-gray-800 text-white"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword({ ...showPassword, confirm: !showPassword.confirm })}
                    className="absolute right-2 top-2 text-sm text-blue-400 hover:underline"
                  >
                    {showPassword.confirm ? "Hide" : "Show"}
                  </button>
                </div>

                <div className="flex gap-4">
                  <button
                    type="button"
                    className="bg-gray-600 px-4 py-2 rounded hover:bg-gray-700 w-full"
                    onClick={() => {
                      setShowPasswordFields(false);
                      setPasswords({ current: "", new: "", confirm: "" });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 px-4 py-2 rounded hover:bg-green-700 w-full"
                  >
                    Change Password
                  </button>
                </div>

                {/* Forgot Password Button */}
                <div className="flex justify-center ">
                  <button
                    type="button"
                    onClick={async () => {
                      try {
                        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/request-password-reset`, {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({ email: user.email }),
                        });
                        const data = await res.json();
                        alert(data.message || "Reset link sent!");
                      } catch (err) {
                        alert("Failed to send reset email.");
                      }
                    }}
                    className="text-blue-400 hover:underline"
                  >
                    Forgot Password?
                  </button>
                </div>
              </form>


              
              

            )}
          </div>
          
        </div>
    
        <div className="text-center pb-6">
          <button
            onClick={() => navigate(-1)}
            className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg"
          >
            ← Back
          </button>
        </div>
      </div>  
  );
}

export default UserSettings;
