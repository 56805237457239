import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/Navbar.jsx';

function Admin() {
  const navigate = useNavigate();

  const handleBackToBlog = useCallback(() => {
    navigate('/BlogHome');
  }, [navigate]);

  useEffect(() => {
    document.title = 'Admin Panel';
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    if (!isAdmin) {
      handleBackToBlog();
    }
  }, [handleBackToBlog]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <Navbar />
      <div className="flex-grow flex items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-4xl font-bold py-2 mt-16">Admin Panel</h1>

          <div className="w-[55%] max-w-3xl space-y-4">

            <div className="w-full max-w-3xl space-y-6">
            <button onClick={() => navigate('/create-blog-post')} className="w-full px-6 py-4 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg">
              Create New Blog Post
            </button>
            </div>
            
            <button 
            onClick={() => navigate('/manage-users')}
            className="w-full px-6 py-4 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg">
              Manage Users
            </button>
            
            <button onClick={() => navigate('/archive')} className="w-full px-6 py-4 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg">
              Archive
            </button>

            <button onClick={() => navigate('/view-all')} className="w-full px-6 py-4 bg-yellow-600 hover:bg-yellow-700 text-white font-semibold rounded-lg">
              View All Blog Posts
            </button>

            <button onClick={() => navigate('/manage-home')} className="w-full px-6 py-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg">
              Manage Home
            </button>

            <button onClick={() => navigate('/edit-resume')} className="w-full px-6 py-4 bg-gray-600 hover:bg-gray-700 text-white font-semibold rounded-lg">
              Edit Resume
            </button>


            
          </div>

          <button
            onClick={() => navigate(-1)}
            className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg"
          >
            ← Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default Admin;
