import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function SkillsPreview({ content, onSave }) {
  const skills = content.find((item) => item.type === "skills");

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    if (skills) setText(skills.description || "");
  }, [skills]);

  if (!skills) return null;

  return (
    <section className="w-full flex flex-col items-center gap-10 px-6 py-16 text-white">
      <h2 className="text-3xl sm:text-5xl font-bold">Skills Section</h2>

      <div
        className="w-full max-w-4xl bg-gray-800 p-6 rounded-lg shadow-md text-center cursor-pointer"
        onClick={() => setIsEditing(true)}
      >
        {isEditing ? (
          <div className="flex flex-col gap-4 items-center">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={6}
              className="w-full p-3 bg-gray-900 text-white rounded resize-none"
              placeholder="Describe your skills..."
            />
            <button
              className="bg-green-600 px-6 py-2 rounded hover:bg-green-700"
              onClick={() => {
                onSave(skills.id, "description", text);
                toast.success("Skills section updated!");
                setIsEditing(false);
              }}
            >
              Save Text
            </button>
          </div>
        ) : (
          <p className="text-lg sm:text-xl leading-relaxed">
            {text || "Click here to add your skills description."}
          </p>
        )}
      </div>
    </section>
  );
}
