import React, { useState, useEffect, useRef } from 'react';

const TerminalInput = ({ input, setInput, handleSubmit, inputRef, history, historyIndex, setHistoryIndex, setInputFromHistory }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setInputFromHistory(history[newIndex]);
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (historyIndex < history.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setInputFromHistory(history[newIndex]);
      } else {
        setHistoryIndex(history.length);
        setInputFromHistory('');
      }
    }
  };

  return (
    <div id="input-line" className="flex items-center px-4 py-2">
      <span className="prompt text-prompt-color font-bold text-2xl mr-2">&gt;</span>
      <form onSubmit={handleSubmit} className="w-full">
        <input
          ref={inputRef}
          id="terminal-input"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full bg-transparent outline-none text-prompt-color border-none pl-2"
          autoFocus
          autoComplete="off"
        />
      </form>
    </div>
  );
};

export default TerminalInput;
