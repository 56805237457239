import React, { useRef, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayer = ({ isAudioPlaying, songSrc }) => {
  const audioRef = useRef(null);

  // Play and control the volume when audio is playing
  useEffect(() => {
    const audioElement = audioRef.current?.audioEl.current;

    if (isAudioPlaying && audioElement) {
      audioElement.volume = 0.4; // Set volume to 40%
      audioElement.play(); // Start playing the audio
    } else if (audioElement) {
      audioElement.pause(); // Pause audio if not playing
    }

    // Cleanup function to pause the audio when the component unmounts or changes
    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0; // Reset the audio playback
      }
    };
  }, [isAudioPlaying, songSrc]);

  return (
    isAudioPlaying && songSrc && (
      <div className="absolute left-1/2 transform -translate-x-1/2 w-full md:w-1/3 z-30 p-6">
        <ReactAudioPlayer
          ref={audioRef}
          src={songSrc}
          controls
          className="w-full"
          
        />
      </div>
    )
  );
};

export default AudioPlayer;
