import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../../components/Navbar";
import MediaList from "./MediaList";
import useMediaManager from "./hooks/UseMediaUpload";

function BlogPostForm() {
  const { id } = useParams();
  const isEditMode = Boolean(id);
  const navigate = useNavigate();
  const contentEditableRef = useRef(null);

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(isEditMode);
  const [content, setContent] = useState("");
  const [savedRange, setSavedRange] = useState(null);
  const [initialContentLoaded, setInitialContentLoaded] = useState(false);

  const {
    mediaList,
    uploading,
    setMediaList,
    uploadMedia,
    addMediaLink,
    removeMedia,
  } = useMediaManager();

  const sanitizeContent = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    doc.querySelectorAll("*").forEach((el) => {
      if (el.className !== "media-placeholder") {
        el.removeAttribute("style");
        el.removeAttribute("data-pm-slice");
      }
    });
    return doc.body.innerHTML;
  };
  

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSavedRange(selection.getRangeAt(0).cloneRange());
    }
  };
  
  const restoreSelection = () => {
    const selection = window.getSelection();
    selection.removeAllRanges();
    if (savedRange) {
      selection.addRange(savedRange);
    }
  };

  const handleContentChange = () => {
    if (contentEditableRef.current) {
      const html = contentEditableRef.current.innerHTML.trim();
      const text = contentEditableRef.current.textContent.trim();
      if (!text) {
        contentEditableRef.current.innerHTML = "";
        setContent("");
      } else {
        setContent(html);
      }
    }
  };

  

  const handlePaste = (e) => {
    e.preventDefault(); // Stop the default paste that inserts bad HTML
    const clipboardText = e.clipboardData.getData("text/plain"); // Get plain text
  
    // Convert plain text to safe HTML with formatting
    const formattedText = clipboardText
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Tabs
      .replace(/ {2}/g, "&nbsp;&nbsp;") // Double spaces
      .replace(/\n/g, "<br>"); // Line breaks
  
    document.execCommand("insertHTML", false, formattedText); // Paste formatted HTML
  };
  

  const handleInsertPlaceholder = (mediaId) => {
    const editor = contentEditableRef.current;
    if (!editor) return;
    editor.focus();
  
    let selection = window.getSelection();
    let range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  
    if (!range || !editor.contains(range.startContainer)) {
      range = document.createRange();
      range.selectNodeContents(editor);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  
    saveSelection();
  

    const placeholderHTML = `<span class="media-placeholder" style="color: #7DFDFE; font-weight: bold; display: inline;">[MEDIA ${mediaId}]</span><span>&ZeroWidthSpace;</span>`;


    document.execCommand("insertHTML", false, placeholderHTML);
  
    saveSelection();
    handleContentChange();
    highlightMediaPlaceholders();
  };  

  const highlightMediaPlaceholders = () => {
    const editor = contentEditableRef.current;
    if (!editor) return;
  
    const walker = document.createTreeWalker(
      editor,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );
  
    let node;
    while ((node = walker.nextNode())) {
      if (node.parentNode.closest(".media-placeholder")) continue;
  
      const match = node.nodeValue.match(/\[MEDIA\s([^\]]+)\]/);
      if (match) {
        const span = document.createElement("span");
        span.className = "media-placeholder";
        span.style.color = "#7DFDFE";
        span.style.fontWeight = "bold";
        span.innerHTML = `${match[0]}`;
        const spacer = document.createElement("span");
        spacer.innerHTML = "&ZeroWidthSpace;";
        span.after(spacer);

  
        const after = node.splitText(match.index);
        after.nodeValue = after.nodeValue.substring(match[0].length);
        node.parentNode.insertBefore(span, after);
      }
    }
  };
  
  
  
  
  

  useEffect(() => {
    if (isEditMode) {
      document.title = 'Edit Post';
      const fetchPost = async () => {
        try {
          const res = await fetch(`http://localhost:3000/api/blogs/${id}`);
          if (!res.ok) throw new Error("Failed to fetch post");
          const data = await res.json();

          setTitle(data.blogPost.title);
          setContent(sanitizeContent(data.blogPost.content));
          setMediaList(data.blogPost.media || []);
          setInitialContentLoaded(true);
        } catch (err) {
          alert(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchPost();
    } else {
      document.title = 'Create New Post';
    }
  }, [id, isEditMode, setMediaList]);

  useEffect(() => {
    if (initialContentLoaded && contentEditableRef.current) {
      contentEditableRef.current.innerHTML = content;
      highlightMediaPlaceholders();
    }
  }, [initialContentLoaded]);
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    let currentContent = contentEditableRef.current?.innerHTML || "";
    currentContent = sanitizeContent(currentContent);

    if (!title.trim() || !currentContent.trim()) {
      return alert("Title and content are required.");
    }

    const payload = new FormData();
    payload.append("title", title);
    payload.append("content", currentContent);
    payload.append("mediaList", JSON.stringify(mediaList));

    try {
      const res = await fetch(`http://localhost:3000/api/blogs${isEditMode ? `/${id}` : ""}`, {
        method: isEditMode ? "PATCH" : "POST",
        body: payload,
      });
      if (!res.ok) throw new Error(`Failed to ${isEditMode ? "update" : "create"} post`);

      alert(`Post ${isEditMode ? "updated" : "created"} successfully!`);
      navigate(isEditMode ? `/blog/${id}` : "/view-all");
    } catch (err) {
      alert(err.message);
    }
  };

  if (loading) return <div className="text-center text-white">Loading...</div>;

  return (
    <div className="min-h-screen bg-black text-white pt-16">
      <Navbar />
      <div className="max-w-5xl mx-auto p-6 grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
        <div className="md:col-span-2 space-y-6">
          <h1 className="text-4xl font-bold text-center">
            {isEditMode ? "Edit Blog Post" : "Create New Blog Post"}
          </h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 bg-gray-700 text-white rounded-lg"
              placeholder="Title"
            />
            <div
              ref={contentEditableRef}
              contentEditable
              onInput={handleContentChange}
              onPaste={handlePaste}
              className="w-full p-2 bg-gray-700 text-white rounded-lg min-h-[300px] whitespace-pre-wrap"
            />

            <button
              type="button"
              onClick={() => {
                const url = window.prompt("Enter Youtube or Instagram URL");
                if (url) addMediaLink(url);
              }}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg"
            >
              Add YouTube/Instagram Link
            </button>
            <div className="flex justify-center">
              <input 
              type="file" 
              onChange={(e) => uploadMedia(e.target.files[0])} 
              />
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={() => navigate(isEditMode ? `/blog/${id}` : "/admin")}
                className="px-6 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg"
              >
                {uploading ? "Uploading..." : isEditMode ? "Save Changes" : "Create Post"}
              </button>
            </div>
          </form>
        </div>
        <div className="max-w-xs mt-16">
          <MediaList
            mediaList={mediaList}
            onInsert={handleInsertPlaceholder}
            onRemove={removeMedia}
          />
        </div>
      </div>
    </div>
  );
}

export default BlogPostForm;
