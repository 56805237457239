import { useState } from "react";
import { getYouTubeTitle, isYouTubeUrl, isInstagramUrl } from "../utils/mediaHelpers";

export default function useMediaManager() {
  const [mediaList, setMediaList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const uploadMedia = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    setUploading(true);
    try {
      const res = await fetch(`${API_BASE_URL}/api/blogs/upload`, {
        method: "POST",
        body: formData,
      });
      if (!res.ok) throw new Error("Upload failed");
      const { imageUrl } = await res.json();

      const mediaId = crypto.randomUUID();
      setMediaList((prev) => [
        ...prev,
        { id: mediaId, type: "image", url: imageUrl, title: `Image ${prev.length + 1}` },
      ]);
    } catch (err) {
      alert(err.message);
    }
    setUploading(false);
  };

  const addMediaLink = async (mediaUrl) => {
    const mediaId = crypto.randomUUID();
    if (isYouTubeUrl(mediaUrl)) {
      const title = await getYouTubeTitle(mediaUrl);
      setMediaList((prev) => [...prev, { id: mediaId, type: "youtube", url: mediaUrl, title }]);
    } else if (isInstagramUrl(mediaUrl)) {
      setMediaList((prev) => [...prev, { id: mediaId, type: "instagram", url: mediaUrl, title: "Instagram Post" }]);
    } else {
      alert("Invalid URL. Only YouTube or Instagram allowed.");
    }
  };

  const removeMedia = (mediaId) => {
    setMediaList((prev) => prev.filter((m) => m.id !== mediaId));
  };

  const loadMediaList = (existingMedia) => {
    const mediaWithIds = (existingMedia || []).map((media) => ({
      ...media,
      id: media.id || crypto.randomUUID(),
    }));
    setMediaList(mediaWithIds);
  };

  return {
    mediaList,
    setMediaList,
    uploading,
    uploadMedia,
    addMediaLink,
    removeMedia,
    loadMediaList,
  };
}
