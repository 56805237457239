// Blog.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar.jsx';
import BlogFooter from './comp/BlogFooter.jsx';
import HomeGrid from './comp/HomeGrid.jsx';
import UserSettings from './Settings/UserSettings.jsx';

function Blog() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  useEffect(() => {
    document.title = 'Blog Home';
  },[]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const loggedInUser = localStorage.getItem('username');

    if (!token) {
      navigate('/terminal');
    } else {
      setUsername(loggedInUser);
    }
  }, [navigate]);

  const navigateToSettings = () => {
    navigate('/settings');

  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      {/* Navbar at the very top */}
      <Navbar />

      <div className='mt-10 border-prompt-color'>
        <button onClick={navigateToSettings} className=''>
          Settings
        </button>
      </div>

      {/* Main content grows to fill space */}
      <main className="flex-grow mt-28 px-6 w-full max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-center">
          Welcome to the Blog, {username}!
        </h1>
        <p className="text-lg text-center mb-8">
          This is a protected route. You can only access this if you're logged in.
          <br />
          Feel free to explore posts, comment on your thoughts, and engage with the community!
        </p>
        <div className="w-[85%] mx-auto flex justify-center items-center">
          <HomeGrid hideTitle />
        </div>
      </main>

      {/* Footer sits at the bottom of the page */}
      <BlogFooter />
    </div>
  );
}

export default Blog;
