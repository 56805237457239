import { motion } from 'framer-motion';
import HomeGrid from '../HomeGrid';

export default function ProjectSection() {
  return (
    <motion.section
      id="projects"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7 }}
      viewport={{ once: true }}
      className="w-full min-h-screen flex flex-col items-center justify-center px-6 text-white"
    >
      <h2 className="text-3xl sm:text-5xl font-bold mb-8 text-center">Projects</h2>

      <div className="w-full max-w-6xl">
        <HomeGrid />
      </div>
    </motion.section>
  );
}
