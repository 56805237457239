import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function HeroSection() {
  const [heroContent, setHeroContent] = useState({
    title: '',
    description: '',
    thumbnail_url: '',
  });

  useEffect(() => {
    const fetchHero = async () => {
      try {
        const res = await fetch(`${BASE_URL}/api/content`);
        const data = await res.json();
        const hero = data.find((item) => item.type === 'hero');
        if (hero) {
          setHeroContent({
            title: hero.title,
            description: hero.description,
            thumbnail_url: hero.thumbnail_url,
          });
        }
      } catch (error) {
        console.error('Error fetching Hero section:', error);
      }
    };

    fetchHero();
  }, []);

  return (
    <motion.section
      id="hero"
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
      className="w-full min-h-screen flex flex-col items-center justify-start gap-10 px-6 pt-24 text-white"
    >
      {/* Image */}
      {heroContent.thumbnail_url ? (
        <img
          src={heroContent.thumbnail_url}
          alt="Hero"
          className="w-48 h-48 rounded-full object-cover shadow-lg"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/150";
          }}
        />
      ) : (
        <div className="w-48 h-48 rounded-full bg-white bg-opacity-10 border border-white flex items-center justify-center text-white text-sm">
          Hero Image
        </div>
      )}

      {/* Text */}
      <div className="text-center max-w-2xl">
        <h1 className="text-4xl sm:text-6xl font-extrabold mb-4">
          {heroContent.title || 'Hi, I’m Derick'}
        </h1>
        <p className="text-xl sm:text-2xl font-light tracking-wide">
          {heroContent.description || 'Creative developer, racer, and builder of digital things.'}
        </p>
      </div>
    </motion.section>
  );
}
