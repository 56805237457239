import React, { useEffect } from 'react';
import DotsCanvas from './DotsCanvas.jsx';
import HomeFooter from './HomeFooter.jsx';
import HomeGrid from './HomeGrid.jsx';

// New section components (you can create these)
import HeroSection from './sections/HeroSection.jsx';
import AboutSection from './sections/AboutSection.jsx';
import ContactSection from './sections/ContactSection.jsx';
import ExperienceSection from './sections/ExperienceSection.jsx';
import SkillsSection from './sections/SkillsSection.jsx';
import ProjectSection from './sections/ProjectSection.jsx';



function Home() {
  useEffect(() => {
    document.title = 'Home';
    document.body.style.overflow = 'auto'; // Allow scroll for resume layout
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="w-screen bg-transparent text-white overflow-x-hidden">
      {/* Header */}
      <header className="fixed top-0 w-full p-4 flex justify-between items-center z-30 bg-transparent">
        <div className="text-2xl sm:text-4xl font-bold tracking-wide text-white text-shadow-lg">
          <a href="/" className="hover:opacity-60">DRCKGOMZ</a>
        </div>
        <div className="text-2xl sm:text-4xl font-bold tracking-wide text-white text-shadow-lg">
          <a href="/terminal" className="hover:opacity-60">TERMINAL</a>
        </div>
      </header>

      {/* Background dots */}
      <div className="fixed inset-0 z-100">
        <DotsCanvas />
      </div>

      {/* Sections */}
      <main className="relative z-10 flex flex-col gap-32 pt-32 pb-20">
        <HeroSection />
        <AboutSection />
        <SkillsSection />
        <ProjectSection />
        <ExperienceSection />
        <ContactSection />
      </main>

      <HomeFooter />
    </div>
  );
}

export default Home;
