import React, { useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const getYouTubeVideoId = (url) =>
  url.match(/(?:v=|\/)([0-9A-Za-z_-]{11})/)?.[1];

export default function MediaCarousel({ media }) {
  const carouselRef = useRef();

  useEffect(() => {
    if (window.instgrm) {
      setTimeout(() => window.instgrm.Embeds.process(), 500);
    }
  }, [media]);

  if (!media || media.length === 0) return null;

  return (
    <div className="relative z-0 mt-20 overflow-hidden">
      <Carousel
        ref={carouselRef}
        showThumbs={false}
        autoPlay
        infiniteLoop
        showStatus={false}
        showIndicators={true}
        transitionTime={500}
        stopOnHover={true}
        className="custom-carousel"
        onChange={() => {
          if (window.instgrm) {
            setTimeout(() => window.instgrm.Embeds.process(), 500);
          }
        }}
      >
        {media.map((item, index) => (
          <div
            key={index}
            className="flex justify-center items-center bg-black py-8"
          >
            {item.type === "image" ? (
              <img
                src={item.url}
                alt={`Image ${index + 1}`}
                className="rounded-lg object-contain max-w-[960px] max-h-[540px]"
              />
            ) : item.type === "youtube" ? (
              <iframe
                width="818"
                height="460"
                src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.url)}`}
                title={`YouTube Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg"
              ></iframe>
            ) : item.type === "instagram" ? (
              <div
                className="flex justify-center items-center bg-black"
                style={{
                  width: "120px",
                  height: "460px",
                }}
              >
                <blockquote
                  className="instagram-media"
                  data-instgrm-permalink={item.url}
                  data-instgrm-version="14"
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#000",
                    border: 0,
                    borderRadius: "8px",
                    margin: 0,
                    padding: 0,
                  }}
                ></blockquote>
              </div>
            ) : null}
          </div>
        ))}
      </Carousel>
    </div>
  );
}
