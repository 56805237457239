import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import ViewAllGrid from "./comp/HomeGrid";
import BlogFooter from "./comp/BlogFooter";
import { useNavigate } from 'react-router-dom';


function AllBlogPosts() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'All Blog Posts'
  },[]);

  return (
    <div className="min-h-screen bg-black justify-items-center text-white pt-16">
      <Navbar /> {/* Navbar at the top */}
      <div className="flex w-[90%] mx-auto pt-6">
        {/* Blog Post Grid */}
        <ViewAllGrid />
      </div>

      {/* Footer sits at the bottom of the page */}
      <button
            onClick={() => navigate(-1)}
            className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg"
          >
            ← Back
          </button>
      <BlogFooter />
    </div>
  );
}

export default AllBlogPosts;
