import React, { useEffect } from "react";

export default function BlogContent({ content, media }) {
  useEffect(() => {
    if (window.instgrm) {
      setTimeout(() => window.instgrm.Embeds.process(), 500);
    }
  }, [content, media]);

  const processContent = () => {
    if (!content) return ""; // Ensure content is always a string
    const safeMedia = Array.isArray(media) ? media : []; // Ensure media is always an array
  
    let processedContent = content;
  
    safeMedia.forEach((mediaItem) => {
      let replacement = "";
  
      if (mediaItem.type === "youtube") {
        const videoId =
          mediaItem.url.split("v=")[1]?.split("&")[0] ||
          mediaItem.url.split("/").pop();
  
        replacement = `
          <div class="flex justify-center my-6">
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/${videoId}"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="rounded-lg max-w-3xl"
            ></iframe>
          </div>
        `;
      } else if (mediaItem.type === "instagram") {
        replacement = `
          <div class="flex justify-center my-6">
            <blockquote class="instagram-media"
              data-instgrm-permalink="${mediaItem.url}"
              data-instgrm-version="14"
              style="background:#000; border:0; border-radius:3px; margin:0; max-width:540px; min-width:326px; padding:0; width:100%;">
            </blockquote>
          </div>
        `;
      } else if (mediaItem.type === "image") {
        replacement = `
          <div class="flex justify-center my-6">
            <img
              src="${mediaItem.url}"
              alt="${mediaItem.title || 'Image'}"
              class="rounded-lg max-w-full"
            />
          </div>
        `;
      }
  
      processedContent = processedContent.replace(
        `[MEDIA ${mediaItem.id}]`,
        replacement
      );
    });
  
    return processedContent;
  };
  

  return (
    <div
      className="text-lg space-y-4"
      dangerouslySetInnerHTML={{ __html: processContent() }}
    />
  );
}
