import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home.jsx';
import Terminal from './pages/Terminal/Terminal.jsx';
import BlogHome from './pages/Blog/BlogHome.jsx';
import Admin from './pages/Blog/Admin/Admin.jsx';
import ManageUsers from './pages/Blog/Admin/manageUsers/ManageUsers.jsx';
import AllBlogPost from './pages/Blog/AllBlogPost.jsx';
import BlogPostView from './pages/Blog/BlogView/BlogPostView.jsx';
import BlogPostForm from './pages/Blog/Admin/managePost/BlogPostForm.jsx';
import ArchivePage from './pages/Blog/Admin/archivePost/ArchivePage.jsx';
import EditResume from './pages/Blog/Admin/editResume/EditResume.jsx';
import ManageHomepage from './pages/Blog/Admin/ManageHomepage/ManageHomepage.jsx';
import UserSettings from './pages/Blog/Settings/UserSettings.jsx';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terminal" element={<Terminal />} />
        <Route path="/BlogHome" element={<BlogHome />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/manage-users" element={<ManageUsers />} />
        <Route path="/create-blog-post" element={<BlogPostForm />} />
        <Route path="/view-all" element={<AllBlogPost />} />
        <Route path="/blog/:id" element={<BlogPostView />} />
        <Route path="/edit/:id" element={<BlogPostForm />} />
        <Route path="/archive" element={<ArchivePage />} />
        <Route path="manage-home" element={<ManageHomepage />} />
        <Route path="edit-resume" element={<EditResume />} />
        <Route path="settings" element={<UserSettings />} />


      </Routes>
    </Router>

      {/* ✅ Toast container added here */}
      <ToastContainer position="bottom-right" autoClose={2000} />
    </>
  );
}

export default App;
